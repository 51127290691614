<template>
  <div class="container-fluid">
    <div class="row landing-page">
      <!-- Background Video -->
      <div class="background-video">
        <video
          playsinline="playsinline"
          autoplay="autoplay"
          muted="muted"
          loop="loop"
        >
          <source
            src="../../assets/videos/background-video.mp4"
            type="video/mp4"
          />
        </video>
      </div>
      <div class="video-overlay"></div>
      <!-- Overlay Div -->
      <!-- Display loader while isLoading is true -->
      <div v-if="isLoading" class="loader">
        <!-- SVG loader -->
        <svg class="spinner" viewBox="0 0 50 50">
          <circle
            class="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            stroke-width="5"
          ></circle>
        </svg>
      </div>
      <!-- Left Column for Logo -->
      <div
        class="col-md-6 d-flex justify-content-center align-items-center pt-5 first"
      >
        <div class="text-center">
          <img
            src="../../assets/home/LB-siteicon.png"
            alt="Latakiano Barbero"
            class="custom-logo-size img-one"
            style="max-width: 200px"
          />
          <img
            src="../../assets/home/tessenderloBarber_text.svg"
            alt=""
            class="custom-logo-size img-two"
            style="width: 175px; height: 55px; position: relative; top: -22px"
          />
        </div>
      </div>
      <!-- Right Column for Text and Buttons -->
      <div
        class="col-md-6 d-flex flex-column justify-content-center text-center pt-3 second"
      >
        <p class="tradition mb-3 mt-3">
          {{ $t('Tradition Meets Trends') }}
        </p>
        <div class="mb-3 mt-3">
          <button
            class="btn btn-dark gold-button book-app-button"
            v-on:click="makeAppointment()"
          >
            {{ $t('MAKE AN APPOINTMENT') }}
          </button>
        </div>
        <div class="mb-3 mt-3">
          <a class="btn btn-outline-light login-btn" @click="contact()">{{
            $t('Contact')
          }}</a>
        </div>
        <div class="mb-3 mt-3">
          <a class="btn btn-outline-light login-btn" @click="pricing()">{{
            $t('Price list')
          }}</a>
        </div>
      </div>
    </div>
    <!-- Modal for Service Selection -->
    <b-modal
      :id="'modal-services'"
      :title="$t('Service Selection')"
      hide-footer
      size="lg"
      centered
    >
      <div class="d-flex" style="height: 100%">
        <!-- Left Side: Services Selection -->
        <div class="modal-left-side flex-grow-1">
          <div class="services-list-container">
            <div
              class="services-list"
              v-for="service in allServices"
              :key="service.id"
              @click="toggleServiceSelection(service)"
              :class="{ selected: isSelected(service) }"
            >
              <div
                class="os-item-img-w-s"
                v-if="service.id === 'c1'"
                style="
                  background-image: url(https://latakiano.be/wp-content/uploads/2023/10/stylist-150x150.png);
                "
              ></div>
              <div
                class="os-item-img-w-s"
                v-if="service.id === 'c2'"
                style="background-image: url(https://i.ibb.co/SKPcDGC/cut.png)"
              ></div>
              <div
                class="os-item-img-w-s"
                v-if="service.id === 'c3'"
                style="
                  background-image: url(https://i.ibb.co/f92hLYP/haircut.png);
                "
              ></div>
              <div
                class="os-item-img-w-s"
                v-if="service.id === 'c4'"
                style="
                  background-image: url(https://latakiano.be/wp-content/uploads/2023/10/beard-trimming-150x150.png);
                "
              ></div>
              <div
                class="os-item-img-w-s"
                v-if="service.id === 'c5'"
                style="background-image: url(https://i.ibb.co/KzyCGM6/long.png)"
              ></div>
              <div
                class="os-item-img-w-s"
                v-if="service.id === 'c6'"
                style="background-image: url(https://i.ibb.co/S0VH3vJ/boy.png)"
              ></div>
              <div
                class="os-item-img-w-s"
                v-if="service.id === 'c7'"
                style="
                  background-image: url(https://i.ibb.co/T21w0PC/female.png);
                "
              ></div>
              <!--input type="checkbox" v-model="selectedServices" :value="service"-->
              <label class="ml-2" style="flex: 4">{{
                $t(service.service)
              }}</label>
              <label>€{{ service.price }}</label>
            </div>
          </div>
          <div class="modal-footer">
            <b-button
              style="background-color: transparent !important"
            ></b-button>
            <b-button @click="goToWorkerSelection">{{
              $t('Next Step')
            }}</b-button>
          </div>
        </div>
        <!-- Right Side: Summary -->
        <div class="modal-right-side">
          <div class="summary-header">
            <span>{{ $t('Summary') }}</span>
          </div>
          <div class="os-summary-contents">
            <div class="booking-summary-info-w">
              <div class="summary-box main-box">
                <div class="summary-box-content">
                  <div class="sbc-big-item">{{ selectedService?.service }}</div>
                  <div class="sbc-highlighted-item"></div>
                </div>
                <div class="summary-attributes sa-clean">
                  <span>Extras: <strong>.....</strong> </span>
                </div>
              </div>
            </div>
            <div class="price-breakdown-w" v-if="selectedServices?.length > 0">
              <div class="pb-heading">{{ $t('Cost Breakdown') }}</div>
              <!-- Iterate over selected services -->
              <div
                v-for="service in selectedServices"
                :key="service.service"
                class="summary-price-item-w"
              >
                <div class="spi-name">
                  {{ $t(service.service) }}
                </div>
                <div class="spi-price">€{{ service.price }}</div>
              </div>
              <!-- Display total price -->
              <div class="summary-price-item-w spi-total">
                <div class="spi-name">
                  {{ $t('Total Price') }}
                </div>
                <div class="spi-price total">€{{ calculateTotalPrice() }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Modal for Worker Selection -->
    <b-modal
      :id="'modal-workers'"
      :title="$t('Worker Info')"
      hide-footer
      size="lg"
      centered
    >
      <div class="d-flex" style="height: 100%">
        <!-- Left Side: Services Selection -->
        <div class="modal-left-side flex-grow-1">
          <!-- Worker Selection Content -->
          <div class="step-agents-w latepoint-step-content">
            <div
              class="os-agents os-animated-parent os-items os-selectable-items os-as-grid os-three-columns"
            >
              <div
                class="os-animated-child os-item os-selectable-item with-details"
                @click="goToDateSelection('Ridir')"
              >
                <div class="os-animated-self os-item-i">
                  <div class="os-item-img-w os-with-avatar">
                    <div
                      class="os-avatar"
                      style="
                        background-image: url(https://ccmihai.github.io/portfolio-template/images/header/me.png);
                      "
                    ></div>
                  </div>
                  <div class="os-item-name-w">
                    <div class="os-item-name">RIDIR</div>
                  </div>
                </div>
              </div>

              <!--div
                class="os-animated-child os-item os-selectable-item with-details"
                @click="goToDateSelection('Maan')"
              >
                <div class="os-animated-self os-item-i">
                  <div class="os-item-img-w os-with-avatar">
                    <div
                      class="os-avatar"
                      style="
                        background-image: url(https://qph.cf2.quoracdn.net/main-thumb-487762713-200-ldqifdohlrsonsomzyrjhrtnvlrjezxp.jpeg);
                      "
                    ></div>
                  </div>
                  <div class="os-item-name-w">
                    <div class="os-item-name">MAAN</div>
                  </div>
                </div>
              </div-->
            </div>
          </div>
          <div class="modal-footer modal-footer-back">
            <b-button class="back-button" @click="goToServiceSelection">
              <font-awesome-icon icon="arrow-left" />
              <span class="ml-2">{{ $t('Back') }}</span>
            </b-button>
          </div>
        </div>
        <!-- Right Side: Summary -->
        <div class="modal-right-side">
          <div class="summary-header">
            <span>{{ $t('Summary') }}</span>
          </div>
          <div class="os-summary-contents">
            <div class="booking-summary-info-w">
              <div class="summary-box main-box">
                <div class="summary-box-content">
                  <div class="sbc-big-item">{{ selectedService?.service }}</div>
                  <div class="sbc-highlighted-item"></div>
                </div>
                <div class="summary-attributes sa-clean">
                  <span
                    >{{ $t('Agent') }}:
                    <strong>{{ this.selectedWorker }}</strong></span
                  >
                </div>
              </div>
            </div>
            <div class="price-breakdown-w">
              <div class="pb-heading">{{ $t('Cost Breakdown') }}</div>
              <div class="summary-price-item-w">
                <div class="spi-name">
                  {{ selectedService?.service }}
                </div>
                <div class="spi-price">€{{ selectedService?.price }}</div>
              </div>
              <div class="summary-price-item-w spi-total">
                <div class="spi-name">
                  {{ $t('Total Price') }}
                </div>
                <div class="spi-price">€{{ selectedService?.price }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Modal for Date & Time Selection -->
    <b-modal
      :id="'modal-date'"
      :title="$t('Appointment')"
      hide-footer
      size="lg"
      centered
    >
      <div class="d-flex date-flex">
        <div class="modal-left-side flex-grow-1">
          <CalendarMonth
            @timePicked="handleTimePicked"
            :selectedWorker="this.selectedWorker"
            :selectedServiceArr="selectedServices"
            :selectedServices="selectedServices?.length"
          />
        </div>
      </div>
      <div class="modal-footer">
        <b-button class="back-button" @click="goToWorkers">
          <font-awesome-icon icon="arrow-left" />
          <span class="ml-2">{{ $t('Back') }}</span>
        </b-button>
        <b-button @click="doneCalendar()">{{ $t('Next Step') }}</b-button>
      </div>
    </b-modal>
    <!-- Client Modal -->
    <b-modal
      :id="'modal-client'"
      :title="$t('Customer Informations')"
      hide-footer
      size="lg"
      centered
    >
      <div class="summary-header">
        <span>{{ $t('Customer Informations') }}</span>
      </div>
      <div class="os-summary-contents">
        <div class="booking-summary-info-w">
          <!-- Client Information Form -->
          <form @submit.prevent="submitForm">
            <!-- Client Name -->
            <b-form-group
              label-for="clientName"
              v-bind="validationState('clientName')"
            >
              <b-form-input
                placeholder="Name*"
                id="clientName"
                v-model="clientName"
                :state="validationState('clientName')"
                required
              ></b-form-input>
            </b-form-group>
            <!-- Last Name -->
            <b-form-group
              label-for="lastName"
              v-bind="validationState('lastName')"
            >
              <b-form-input
                placeholder="Last Name*"
                id="lastName"
                v-model="lastName"
                :state="validationState('lastName')"
                required
              ></b-form-input>
            </b-form-group>
            <!-- Email -->
            <b-form-group label-for="clientEmail">
              <b-form-input
                placeholder="Email"
                id="clientEmail"
                type="email"
                v-model="clientEmail"
              ></b-form-input>
            </b-form-group>
            <!-- Phone Number -->
            <b-form-group label-for="phone">
              <b-form-input
                placeholder="Phone Number"
                id="phone"
                type="tel"
                pattern="\d{0,10}"
                v-model="clientPhone"
                :maxlength="10"
                title="Phone number must be 10 digits long"
              ></b-form-input>
            </b-form-group>
            <!-- Submit Button -->
            <div class="modal-footer special-footer">
              <b-button class="back-button" @click="goToCalendar">
                <font-awesome-icon icon="arrow-left" />
                <span class="ml-2">{{ $t('Back') }}</span>
              </b-button>
              <b-button type="submit">{{ $t('Complete Booking') }}</b-button>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
    <!-- Modal for Summary -->
    <b-modal
      :id="'modal-summary'"
      :title="$t('Summary')"
      hide-footer
      size="lg"
      centered
    >
      <div
        class="os-summary-contents"
        style="padding: 0 8px 15px 8px !important"
      >
        <div class="booking-summary-info-w">
          <div class="summary-box main-box">
            <div class="summary-box-content">
              <div class="sbc-big-item text-center">
                {{ clientSelectedDate }} {{ $t('at') }} {{ updatedTime }}
              </div>
            </div>
            <div class="summary-attributes sa-clean">
              <div class="inline-avatar">
                <div
                  class="os-avatar-small"
                  v-if="this.selectedWorker === 'Ridir'"
                  style="
                    background-image: url(https://ccmihai.github.io/portfolio-template/images/header/me.png);
                  "
                ></div>
                <div
                  class="os-avatar-small"
                  v-if="this.selectedWorker === 'Maan'"
                  style="
                    background-image: url(https://qph.cf2.quoracdn.net/main-thumb-487762713-200-ldqifdohlrsonsomzyrjhrtnvlrjezxp.jpeg);
                  "
                ></div>
                <div class="agent-container">
                  <span class="agent"
                    >{{ $t('Agent') }}:
                    <strong>{{ this.selectedWorker }}</strong></span
                  >
                  <span class="agent" v-if="phone"
                    >{{ $t('Phone') }}: <strong>{{ phone }}</strong></span
                  >
                  <span class="agent"
                    >{{ $t('Email') }}: <strong>{{ email }}</strong></span
                  >
                  <span class="agent"
                    >{{ $t('Fb') }}: <strong>{{ fbCalendar }}</strong></span
                  >
                </div>
                <div class="agent mt-2 ml-4">
                  <strong>{{ address }}</strong>
                </div>
              </div>
            </div>
            <div class="summary-attributes sa-clean cancellation-container">
              <strong class="text-danger text-uppercase">
                <font-awesome-icon icon="exclamation-triangle" />
                <span class="ml-1 cancelation">{{
                  $t('For cancellations, please call or send an SMS')
                }}</span>
              </strong>
            </div>
          </div>
        </div>
        <div class="price-breakdown-w">
          <div class="pb-heading">{{ $t('Customer Informations') }}</div>
          <div class="summary-price-item-w">
            <div class="spi-name">{{ clientName }} {{ lastName }}</div>
          </div>
          <div class="summary-price-item-w">
            <div class="spi-name">{{ clientEmail }}</div>
          </div>
          <div class="summary-price-item-w" v-if="clientPhone">
            <div class="spi-name">{{ clientPhone }}</div>
          </div>
        </div>
        <div class="price-breakdown-w" v-if="selectedServices?.length > 0">
          <div class="pb-heading">{{ $t('Cost Breakdown') }}</div>

          <!-- Iterate over selected services -->
          <div
            v-for="service in selectedServices"
            :key="service.service"
            class="summary-price-item-w"
          >
            <div class="spi-name">
              {{ $t(service.service) }}
            </div>
            <div class="spi-price">€{{ service.price }}</div>
          </div>

          <!-- Display total price -->
          <div class="summary-price-item-w spi-total">
            <div class="spi-name">
              {{ $t('Total Price') }}
            </div>
            <div class="spi-price total">€{{ calculateTotalPrice() }}</div>
          </div>
        </div>
      </div>
      <div
        class="modal-footer"
        style="position: absolute; top: 56%; width: 88%; border: none"
      >
        <b-button
          class="back-button"
          @click="goToClient"
          style="background-color: #ff7f03 !important"
        >
          <font-awesome-icon icon="arrow-left" />
          <span class="ml-2">{{ $t('Back') }}</span>
        </b-button>
        <b-button
          id="confirm-booking"
          style="background-color: #ff7f03 !important"
          @click="doneBooking"
        >
          {{ $t('Done') }}
          <font-awesome-icon
            class="icon-close"
            icon="arrow-right"
            style="margin-left: 2px"
          />
        </b-button>
      </div>
    </b-modal>
    <!-- Modal for Thank you -->
    <b-modal
      :id="'modal-thanks'"
      :title="$t('Summary')"
      hide-footer
      size="lg"
      centered
    >
      <div class="screen un">
        <div class="border-top"></div>
        <svg width="166" height="150" id="topIcon">
          <g id="Shot" fill="none" fill-rule="evenodd">
            <g id="shot2" transform="translate(-135 -157)">
              <g id="success-card" transform="translate(48 120)">
                <g id="Top-Icon" transform="translate(99.9 47.7)">
                  <g id="Bubbles" fill="#4a934a">
                    <g id="bottom-bubbles" transform="translate(0 76)">
                      <ellipse
                        id="Oval-Copy-3"
                        cx="12.8571429"
                        cy="13.2605405"
                        rx="12.8571429"
                        ry="12.8432432"
                      />
                      <ellipse
                        id="Oval-Copy-4"
                        cx="25.0714286"
                        cy="34.4518919"
                        rx="8.35714286"
                        ry="8.34810811"
                      />
                      <ellipse
                        id="Oval-Copy-6"
                        cx="42.4285714"
                        cy="31.2410811"
                        rx="7.71428571"
                        ry="7.70594595"
                      />
                    </g>
                    <g id="top-bubbles" transform="translate(92)">
                      <ellipse
                        id="Oval"
                        cx="13.4285714"
                        cy="23.76"
                        rx="12.8571429"
                        ry="12.8432432"
                      />
                      <ellipse
                        id="Oval-Copy"
                        cx="37.8571429"
                        cy="25.0443243"
                        rx="5.14285714"
                        ry="5.1372973"
                      />
                      <ellipse
                        id="Oval-Copy-2"
                        cx="30.1428571"
                        cy="7.70594595"
                        rx="7.71428571"
                        ry="7.70594595"
                      />
                    </g>
                  </g>
                  <g id="Circle" transform="translate(18.9 11.7)">
                    <ellipse
                      id="blue-color"
                      cx="56.341267"
                      cy="54.0791109"
                      fill="#4a934a"
                      rx="51.2193336"
                      ry="51.5039151"
                    />
                    <ellipse
                      id="border"
                      cx="51.2283287"
                      cy="51.5039151"
                      stroke="#3C474D"
                      stroke-width="5"
                      rx="51.2193336"
                      ry="51.5039151"
                    />
                    <!-- Bolder and slightly left and top-shifted checkmark icon -->
                    <path
                      id="check-icon"
                      fill="#FFF"
                      fill-rule="nonzero"
                      d="M 84.4528,34.8563
            C 84.0528,34.4563 83.4128,34.4563 83.0128,34.8563
            L 45.0128,72.8563
            L 31.0128,58.8563
            C 30.6128,58.4563 29.9728,58.4563 29.5728,58.8563
            C 29.1728,59.2563 29.1728,59.8963 29.5728,60.2963
            L 43.5728,74.2963
            C 43.9728,74.6963 44.6128,74.6963 45.0128,74.2963
            L 84.4528,34.8563 Z"
                      transform="translate(4, -4)"
                      style="stroke: #fff; stroke-width: 3px"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <h3>GELUKT!</h3>
        <p>
          U afspraak is bevestigd <br />
          <b>{{ clientSelectedDate }}</b> {{ $t('at') }} <b>{{ updatedTime }}</b
          >.
        </p>
        <button id="btnClick" @click="doneThx">{{ $t('Close') }}</button>
      </div>
    </b-modal>
    <!-- Modal for Contact -->
    <b-modal
      :id="'modal-contact'"
      :title="$t('Contact Us')"
      hide-footer
      size="lg"
      centered
    >
      <div class="os-summary-contents">
        <div class="booking-summary-info-w">
          <div class="summary-box main-box">
            <div class="summary-attributes sa-clean pricing-text-t">
              <font-awesome-icon
                icon="phone"
                style="
                  border-right: 1px solid;
                  padding-right: 3px;
                  font-size: 20px;
                "
              />
              <a :href="'tel:' + phone" style="color: #8e97b3">
                <span class="ml-1"
                  ><strong> {{ phone }}</strong></span
                >
              </a>
            </div>
            <div class="summary-attributes sa-clean pricing-text-t">
              <font-awesome-icon
                icon="envelope"
                style="
                  border-right: 1px solid;
                  padding-right: 3px;
                  font-size: 20px;
                "
              />
              <span class="ml-1"
                ><strong> {{ email }}</strong></span
              >
            </div>
            <div class="summary-attributes sa-clean pricing-text-t">
              <font-awesome-icon
                :icon="['fab', 'facebook']"
                class="icon alt"
                style="
                  border-right: 1px solid;
                  padding-right: 3px;
                  font-size: 20px;
                "
              />
              <span class="ml-1"
                ><strong> {{ fbCalendar }}</strong></span
              >
            </div>
            <div class="summary-attributes sa-clean pricing-text-t">
              <font-awesome-icon
                icon="map-marker-alt"
                style="
                  border-right: 1px solid;
                  padding-right: 3px;
                  font-size: 20px;
                "
              />
              <span class="ml-1"
                ><strong> {{ address }}</strong></span
              >
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal-footer"
        style="top: 54%; position: absolute; width: 90%; border: none"
      >
        <b-button style="background-color: transparent !important"></b-button>
        <b-button @click="closeContact()">
          <font-awesome-icon class="icon-close" icon="times" />{{ $t('Close') }}
        </b-button>
      </div>
    </b-modal>
    <!-- Modal for Pricing -->
    <b-modal
      :id="'modal-pricing'"
      :title="$t('Pricing List')"
      hide-footer
      size="lg"
      centered
    >
      <div class="os-summary-contents">
        <div class="booking-summary-info-w">
          <div class="summary-box main-box">
            <div
              class="summary-attributes sa-clean pricing-text"
              v-for="service in allServices"
              :key="service.id"
            >
              <span style="flex: 1"
                ><strong>{{ $t(service.service) }} </strong></span
              >
              <span
                ><strong>€{{ service?.price }}</strong></span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <b-button style="background-color: transparent !important"></b-button>
        <b-button @click="closePricing()"
          ><font-awesome-icon class="icon-close" icon="times" />{{
            $t('Close')
          }}</b-button
        >
      </div>
    </b-modal>
    <!-- Modal for Balance -->
    <b-modal
      :id="'modal-balance'"
      :title="$t('Balance')"
      hide-footer
      size="lg"
      centered
    >
      <div class="summary-header">
        <span>{{ $t('Balance') }}</span>
      </div>
      <div class="os-summary-contents">
        <div class="booking-summary-info-w">
          <div class="summary-box main-box">
            <div
              class="summary-attributes sa-clean pricing-text text-left"
              v-if="!balanceChecked"
            >
              <span
                ><strong>{{ $t('Please enter your mail') }}</strong></span
              >
              <form @submit.prevent="checkBalance" class="mt-3">
                <!-- Email -->
                <b-form-group>
                  <b-form-input
                    placeholder="Email"
                    id="clientEmail"
                    type="email"
                    v-model="enterEmail"
                    required
                  ></b-form-input>
                </b-form-group>
                <!-- Submit Button -->
                <div class="modal-footer" style="padding: 20px 8px !important">
                  <b-button
                    type="submit"
                    class="back-button"
                    style="
                      position: absolute;
                      bottom: 50px;
                      color: #ffffff !important;
                    "
                  >
                    <span>{{ $t('Check Balance') }}</span>
                  </b-button>
                </div>
              </form>
              <!-- Error Message -->
              <div v-if="showEmptyBalanceError" class="error-message">
                {{ errorMessage }}
              </div>
            </div>
            <div
              class="summary-attributes sa-clean pricing-text text-center"
              v-if="balanceChecked"
            >
              <span
                ><strong
                  >{{ clientInfo.name }} {{ clientInfo.lastName }}</strong
                ></span
              ><br />
              <span
                ><strong>{{ clientInfo.email }}</strong></span
              ><br /><br />
              <span v-if="clientInfo?.points"
                ><strong style="color: #caa569">{{
                  displayStars(clientInfo.points, 10)
                }}</strong></span
              >
              <span v-if="clientBookedTimes?.length > 0">
                <strong>{{ $t('Booked Times') }}:</strong>
                <ul
                  v-for="booked in clientBookedTimes"
                  :key="booked.time"
                  style="list-style: none"
                >
                  <li :class="{ 'booked-already': booked }">
                    {{ booked.date }} om {{ booked.time }}
                  </li>
                </ul>
              </span>
              <span v-else>{{ $t('No booked time!') }}</span>
            </div>
            <div
              class="modal-footer"
              style="
                padding: 20px 8px !important;
                float: left;
                background: none;
                border: none;
              "
            >
              <b-button
                style="background-color: transparent !important"
              ></b-button>
              <b-button class="back-button" @click="closeBalance">
                <span
                  ><font-awesome-icon class="icon-close" icon="times" />{{
                    $t('Close')
                  }}</span
                >
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Account -->
    <!--div class="account-selection">
      <a class="ml-4" @click="openBalance"><font-awesome-icon icon="shopping-bag"/></a>
    </div-->
    <!-- Language Selection Buttons -->
    <div class="home__language-selection">
      <b-button
        class="btn btn-outline-light language-btn language-btn-nl"
        @click="goToMenuNl"
      >
        Nl
      </b-button>
      <span class="language-separator"></span>
      <b-button
        class="btn btn-outline-light language-btn language-btn-en"
        @click="goToMenuEn"
      >
        En
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CalendarMonth from './calendar/CalendarMonth';
import axios from 'axios';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';

export default {
  name: 'Home',
  data() {
    return {
      isLoading: false,
      showToast: false, // This controls whether the toast is shown
      clientBookedTimes: [], // New property to store booked times for the client
      showEmptyBalanceError: false,
      errorMessage: 'Your mail dont exist by us! or no balance!',
      balanceChecked: false, // New property to track if balance has been checked
      clientPhone: '',
      selectedServices: [], // Change to an array
      selectedService: null,
      selectedWorker: 'Ridir',
      clientSelectedDate: null,
      selectedDate: null,
      selectedTime: null,
      updatedTime: null,
      enterEmail: '', // Initialize the data property for the entered email
      clientInfo: null, // Initialize clientInfo as null
      phone: '04 881 67 677',
      email: 'ridirsh@gmail.com',
      fbCalendar: 'Tessenderlobarbershop',
      address: 'Stationsstraat 22, 3980 Tessenderlo',
      clientName: '',
      lastName: '',
      clientEmail: '',
      formValid: false,
      validation: {}, // Initialize validation object
      name: '',
      target: '',
      subject: '',
      message: '',
    };
  },
  computed: {
    ...mapGetters(['allServices']),
    condition() {
      return this.selectedDate && this.updatedTime;
    },
  },
  async created() {
    await this.goToMenuNl();
  },
  components: {
    CalendarMonth,
  },
  methods: {
    ...mapActions(['fetchServices']),

    toggleServiceSelection(service) {
      const index = this.selectedServices.findIndex((s) => s.id === service.id);
      if (index === -1) {
        // Service not in the array, add it
        this.selectedServices.push(service);
      } else {
        // Service already in the array, remove it
        this.selectedServices.splice(index, 1);
      }
    },
    isSelected(service) {
      return this.selectedServices.some((s) => s.id === service.id);
    },
    goToWorkerSelection() {
      if (this.selectedServices.length > 0) {
        this.$bvModal.hide('modal-services');
        this.selectedWorker = 'Ridir';
        this.$bvModal.show('modal-date');
        //this.$bvModal.show('modal-workers');
      }
    },
    goToServiceSelection() {
      this.$bvModal.hide('modal-workers');
      this.$bvModal.show('modal-services');
      this.selectedWorker = null;
    },
    makeAppointment() {
      this.$bvModal.show('modal-services');
      this.fetchServices();
    },
    goToWorkers() {
      this.$bvModal.hide('modal-date');
      this.$bvModal.show('modal-workers');
    },
    goToCalendar() {
      this.$bvModal.hide('modal-client');
      this.$bvModal.show('modal-date');
    },
    goToClient() {
      this.$bvModal.hide('modal-summary');
      this.$bvModal.show('modal-client');
    },
    goToDateSelection(worker) {
      console.log(worker);
      this.$bvModal.hide('modal-workers');
      this.$bvModal.show('modal-date');
      this.selectedWorker = 'Ridir';
    },
    doneCalendar() {
      if (this.condition) {
        // Logic when conditions are met
        this.$bvModal.hide('modal-date');
        this.$bvModal.show('modal-client');
      } else {
        // Directly show toast
        this.showToast = true;
        this.showNotification();
      }
    },
    showNotification() {
      if (this.showToast) {
        this.$bvToast.toast(`Aub Kies Datum en Tijd`, {
          title: 'Opgelet!',
          variant: 'danger',
          // Specifies the toast to appear at the bottom-right
          //toaster: 'b-toaster-bottom-right',
          autoHideDelay: 1000,
          appendToast: true,
          solid: true,
        });
      }
    },
    hideToast() {
      this.showToast = false; // This will not directly hide an already shown toast, but you can prevent further toasts
      // For an immediate effect, you might need to directly manipulate the DOM or use a different strategy to keep track and close the toast.
    },
    submitForm() {
      // Validate the form
      this.validateForm();

      // If form is valid, proceed with booking
      if (this.formValid) {
        this.completeBooking();
      }
    },
    validateForm() {
      // Reset form validation state
      this.formValid = true;
      // Validate client name
      if (!this.clientName.trim()) {
        this.formValid = false;
        this.$set(this.validation, 'clientName', false);
      }
      // Validate last name
      if (!this.lastName.trim()) {
        this.formValid = false;
        this.$set(this.validation, 'lastName', false);
      }

      // You can add more validation logic for the phone number if needed
    },
    validationState(field) {
      // Helper method to provide validation state for BootstrapVue components
      return this.validation[field] === false ? false : null;
    },
    completeBooking() {
      this.$bvModal.hide('modal-client');
      this.$bvModal.show('modal-summary');
    },
    contact() {
      this.$bvModal.show('modal-contact');
    },
    async closeBalance() {
      try {
        // Set loading state to true
        this.isLoading = true;

        // Hide the modal
        this.$bvModal.hide('modal-balance');

        // Simulate a delay (you can replace this with your actual logic)
        await new Promise((resolve) => setTimeout(resolve, 500));

        // Your other logic...
        localStorage.setItem('reloaded', '1');
        location.reload();
      } catch (error) {
        console.error('Error during closeBalance:', error);
      } finally {
        // Reset loading state after the operation is complete
        this.isLoading = false;
      }
    },
    closeContact() {
      this.$bvModal.hide('modal-contact');
    },
    pricing() {
      this.$bvModal.show('modal-pricing');
    },
    closePricing() {
      this.$bvModal.hide('modal-pricing');
    },
    goToMenuEn: function () {
      this.$i18n.locale = 'en';
    },
    goToMenuNl: function () {
      this.$i18n.locale = 'nl';
    },
    formatDate(date) {
      if (!(date instanceof Date)) {
        // If date is not a Date object, create one or handle it appropriately
        date = new Date(date);
      }

      if (isNaN(date.getTime())) {
        // Handle invalid date
        console.error('Invalid date:', date);
        return null;
      }

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    displayStars(points, totalStars) {
      const filledStars = Math.min(points, totalStars); // Each star represents 1 point

      const starsArray = Array(filledStars).fill('★'); // Filled stars
      const emptyStarsArray = Array(totalStars - filledStars).fill('☆'); // Empty stars

      return [...starsArray, ...emptyStarsArray].join(' '); // Join the stars into a string
    },
    handleTimePicked({ selectedDate, updatedTime }) {
      this.updatedTime = updatedTime;
      this.selectedDate = this.formatDate(selectedDate); //here

      this.clientSelectedDate = this.formatDate(selectedDate).split('-'); //here
      this.clientSelectedDate =
        this.clientSelectedDate[2] +
        '-' +
        this.clientSelectedDate[1] +
        '-' +
        this.clientSelectedDate[0];
    },
    calculateTotalPrice() {
      try {
        const totalPrice = this.selectedServices.reduce(
          (total, service) => total + parseFloat(service.price),
          0,
        );
        let formattedTotal = totalPrice.toLocaleString('en-US', {
          minimumFractionDigits: 2,
        });
        // Remove the fraction part if it's .00
        formattedTotal = formattedTotal.endsWith('.00')
          ? formattedTotal.split('.')[0]
          : formattedTotal;
        return formattedTotal;
      } catch (error) {
        console.error('Error calculating total price:', error);
        return '€0.00'; // Return a default value in case of an error
      }
    },
    async openBalance() {
      this.$bvModal.show('modal-balance');
    },
    async updateCalendarDataInDataBase(bookingData) {
      try {
        const workerName = this.selectedWorker;
        const formattedDate = dayjs(this.selectedDate).format('YYYY-MM-DD');
        const timeSlot = this.updatedTime;

        // Parse updatedTime to ensure it's in a format that Day.js can understand
        const startTime = this.updatedTime.split(' - ')[0]; // Assuming updatedTime format is "HH:mm - HH:mm"

        // Calculate the total duration based on services
        let totalDuration = 0;
        this.selectedServices.forEach((service) => {
          console.log(service);
          // Assuming there's a property 'duration' in each service representing its duration
          const serviceDuration = 15;
          totalDuration += serviceDuration;
        });

        // Add the total duration to the start time to get the end time
        const endTime = dayjs(this.selectedDate + ' ' + startTime)
          .add(totalDuration, 'minutes')
          .format('HH:mm');

        const databaseURL = `https://tessenderlobarbershop-d74df-default-rtdb.europe-west1.firebasedatabase.app/${workerName}/calendar/${formattedDate}/${timeSlot}.json`;

        await axios.patch(databaseURL, bookingData);

        this.$bvModal.hide('modal-summary');

        // Generate ICS content
        const icsContent = [
          'BEGIN:VCALENDAR',
          'VERSION:2.0',
          'BEGIN:VEVENT',
          `DTSTART:${dayjs(this.selectedDate + ' ' + startTime).format(
            'YYYYMMDDTHHmmss',
          )}`,
          `DTEND:${dayjs(this.selectedDate + ' ' + endTime).format(
            'YYYYMMDDTHHmmss',
          )}`,
          `SUMMARY:AFSPRAAK MET KAPPER : ${workerName}`,
          `DESCRIPTION:${this.selectedServices
            .map((service) => service.service)
            .join(', ')}`,
          `LOCATION:${this.address}`, // Add the address
          `ORGANIZER;CN=${workerName}:mailto:${this.email}`, // Assuming email is available
          `ATTENDEE;CN=${workerName};ROLE=REQ-PARTICIPANT;RSVP=TRUE:mailto:${this.email}`, // Assuming email is available
          `TEL:${this.phone}`, // Add the phone number
          'END:VEVENT',
          'END:VCALENDAR',
        ].join('\r\n');

        // Trigger download
        const blob = new Blob([icsContent], {
          type: 'text/calendar;charset=utf-8',
        });
        saveAs(blob, 'appointment.ics');
      } catch (error) {
        console.error('Booking error:', error);
      }
    },
    async doneBooking() {
      const token = Math.random().toString(36).slice(-8).toUpperCase();
      try {
        const bookingData = {
          booked: true,
          worker: this.selectedWorker,
          services: this.selectedServices,
          client: {
            id: token,
            name: this.clientName,
            lastName: this.lastName,
            email: this.clientEmail,
            phone: this.clientPhone,
          },
        };
        await this.updateCalendarDataInDataBase(bookingData);

        this.$bvModal.show('modal-thanks');
      } catch (error) {
        console.error('Booking error:', error);
      }
    },
    async doneThx() {
      localStorage.setItem('reloaded', '1');
      location.reload();
    },
  },
  watch: {
    // Watcher for both conditions
    condition() {
      if (this.selectedDate && this.updatedTime) {
        this.hideToast(); // Hide toast if conditions are met
      }
    },
  },
};
</script>

<style scoped lang="scss">
.os-item-img-w-s {
  flex: 0 0 35px;
  height: 45px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  margin-right: 5px;
  display: block;
}

.icon-close {
  position: relative;
  top: -1px;
  left: -3px;
}

.service-clock {
  display: block;
  font-size: 14px;
  color: #caa569;
}

.background-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Ensure video stays in the background */
  opacity: 0.75;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 0; /* Overlay sits above the video but below content */
}

.landing-page {
  position: relative;
  height: 100vh; /* Adjust height as needed */
  overflow: hidden;
}

/* Base styles */
.date-flex {
  height: 100%;
}

.custom-logo-size {
  display: block;
  max-width: 100%;
  height: auto;
}

.tradition {
  font-size: clamp(16.834px, 1.052rem + ((1vw - 3.2px) * 0.955), 26px);
  font-style: normal;
  font-weight: 900;
  letter-spacing: 0.08em;
  line-height: 1.4;
  text-decoration: none;
  text-transform: uppercase;
  color: #f9f9f9;
}

.book-app-button,
.login-btn {
  padding: clamp(10px, 2.5vw, 20px) clamp(12px, 2.5vw, 25px);
  font-size: clamp(12px, 1.5vw, 17px);
}

.book-app-button {
  padding: 20px 25px;
  font-size: 17px;
  letter-spacing: 2px;
  font-weight: 900;
  background-color: #caa569 !important;
}

.login-btn {
  font-size: clamp(14px, 0.875rem + ((1vw - 3.2px) * 0.313), 17px);
  color: #caa569 !important;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 3px;
  text-decoration: none;
  text-transform: uppercase;
  border: 0;
  border-bottom: 1px solid;
  border-radius: 0;
}

.gold-button {
  background-color: #caa569 !important;
}

/* Modal styles */
.modal-left-side {
  padding: 0 1rem 0 0;
  border-right: 2px solid #e5e7ee;
}

@media (max-width: 400px) {
  .modal-left-side {
    padding: 0 0 0 0;
    border-right: none;
  }
}

.modal-right-side {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.modal-footer {
  padding: 20px 30px;
  border-top: 1px solid #dee2e6;
  background: #f8f9fa;
  display: flex;
  justify-content: space-between;

  > button {
    font-weight: 900;
    box-shadow: none;
    padding: 10px 14px;
    font-size: 12px;
    letter-spacing: 0.5px;
    background-color: #caa583 !important;
    border-radius: 0;
    text-decoration: none !important;
    display: inline-block;
    border: 0;
    line-height: 1.2;
  }
}

.modal-footer-back {
  justify-content: flex-start !important;
}

.summary-header {
  background-image: radial-gradient(#d4d4d4 1px, transparent 0);
  background-size: 7px 7px;
  display: flex;
  padding: 12px 20px;

  > span {
    background-color: #f5f5f5;
    font-size: 14px;
    line-height: 1.3;
    color: #14161d;
    font-weight: 600;
    padding: 5px;
    text-transform: uppercase;
    letter-spacing: 2px;
    position: relative;
    z-index: 2;
    display: inline-block;
  }
}

.summary-body {
  padding: 25px 30px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.service-detail {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  font-weight: 900;
  font-size: 16px;
}

.services-list {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  display: flex; // Use Flexbox
  justify-content: space-between; // Align items to the start and end
  background: #fff;
  border: 1px solid #e5e7ee;
  align-items: center;
  box-shadow: none;
  outline: 0;
  color: #1f222b;
  text-decoration: none;
  animation: 0.4s cubic-bezier(0.45, 1, 0.4, 1.2) 0ms LatepointSlideUp;
  margin-bottom: 1rem;
  padding: 1rem 0 0.7rem 0;

  &.selected {
    /* Your existing styles for selected items */
    box-shadow: 0 0 0 2px #caa583;
    > label {
      color: #caa583;
      font-weight: 800;
    }
  }

  label {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
      Roboto, 'Helvetica Neue', Arial, sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #1f222b;
    cursor: pointer;
    flex: 1; // Allow label to take remaining space
    margin-bottom: 0;
  }

  input[type='checkbox'] + label {
    margin-left: auto; // Push the price label to the right
  }
}

.services-list > input {
  background-color: #fff;
  color: #fff;
  padding: 3px;
  font-size: 12px;
  font-family: 'latepoint', sans-serif !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '';
  display: block;
  transform: translateY(-50%);
  box-shadow: inset 0 0 0 1px #d8dbe3;
}

.services-list input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  color: #fff;
  padding: 3px;
  font-size: 12px;
  border: 1px solid #d8dbe3;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: relative;
  top: 0;
  left: 10px;
  transform: translateY(-20%);
  cursor: pointer;
  margin-right: 15px; // Space between checkbox button and label

  &:checked {
    background-color: #caa569; /* Change color when selected */
    border-color: #caa569; /* Optional: change border color when selected */
    /* Custom styles for the checked state */
    box-shadow: 0 0 0 2px #caa569; /* Highlight selected service */
  }

  &:focus {
    outline: none; /* Removes default focus outline */
    box-shadow: 0 0 0 2px rgba(202, 165, 105, 0.5); /* Custom focus style */
  }
}

/* Media queries for responsiveness */
@media (max-width: 412px) {
  .custom-logo-size {
    height: 160px;
  }

  .landing-page {
    flex-direction: column;
  }

  .services-list {
    padding: 0.7rem 0 0.7rem 0;
  }
}

@media (max-width: 360px) {
  .custom-logo-size {
    height: 100px;
  }

  .img-one {
    position: relative;
    left: 10%;
  }

  .img-two {
    width: 145px !important;
    height: 60px !important;
  }

  .landing-page {
    flex-direction: column;

    .col-md-6 {
      padding-top: 0 !important;
    }

    .first {
      position: absolute;
      top: 2.2%;
    }

    .second {
      position: relative;
      top: 35%;
      > div {
        margin: 5px 0 5px 0 !important;
        > a {
          font-size: 10px;
          letter-spacing: 3px;
        }
      }
    }

    .book-app-button {
      //padding: 12px 8px;
      //font-size: 13px;
      //letter-spacing: 0.35px;
      //font-weight: 500;
      margin: 0 0 0 0;
    }

    .tradition {
      padding: 0 0 0 0;
      margin: 0 0 0 0;
    }

    .services-list {
      padding: 0.4rem 0 0.4rem 0;
    }
  }
}

input[type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid #d8dbe3;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 15px; /* Adjust as needed */
  display: inline-block; /* Ensures the element is not hidden */
}

input[type='radio']:checked {
  background-color: #caa569;
}

input[type='radio']:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(202, 165, 105, 0.5);
}

.os-summary-contents {
  padding: 15px 0 15px 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.summary-box.main-box {
  padding-bottom: 10px;
  border-bottom: 1px solid #f1f1f1;
}

.summary-attributes {
  padding: 10px;
  background-color: #f7f7f7;
  color: #8e97b3;
  font-size: 12px;
  margin-top: 15px;
}

.summary-boxes-columns {
  display: block;
}

.price-breakdown-w {
  padding: 25px 30px 30px;
  margin: 20px -30px -30px;
  border-top: 1px solid #eee;
}

.price-breakdown-w .pb-heading {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  border-bottom: 1px solid #f1f1f1;
  padding-bottom: 5px;
  margin-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 11px;
  font-weight: 500;
  color: #8e97b3;
}

.price-breakdown-w .summary-price-item-w {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
}

.breakdown-w .summary-box-heading {
  display: none;
}

.latepoint-w .latepoint-summary-w .summary-price-item-w {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.latepoint-step-content {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  color: #8e97b3;
  min-height: 200px;
  text-align: left;
  transition: all 0.3s cubic-bezier(0.25, 1.15, 0.5, 1.2);
  opacity: 1;
  transform: translateY(0);
}

.summary-price-item-w .spi-name,
.summary-price-item-w .spi-price {
  color: #1f222b;
  font-weight: 400;
  font-size: 10px;
}

.summary-price-item-w .spi-price {
  color: #1f222b;
  font-weight: 800;
}

.summary-box-heading .sbh-item {
  text-transform: uppercase;
  color: #999;
  font-weight: 500;
  letter-spacing: 1px;
  position: relative;
  font-size: 11px;
}

.summary-box-heading .sbh-line {
  height: 1px;
  background-color: #f1f1f1;
  flex: 1;
  margin-left: 5px;
}

.summary-box .sbc-big-item {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  font-size: 17px;
  font-weight: 700;
  color: #1f222b;
  margin-bottom: 5px;
}

.os-items.os-as-grid.os-three-columns {
  display: grid;
  margin: 0 !important;
  padding: 0 !important;
  grid-template-columns: 1fr 1fr;
  column-gap: 0;
  row-gap: 0;
  //column-gap: 15px;
  //row-gap: 15px;
}

.os-items.os-as-grid .os-item {
  text-align: center;
  position: relative;
}

.os-items .os-item .os-item-i {
  cursor: pointer;
  display: flex;
  background: #fff;
  border: 1px solid #e5e7ee;
  align-items: center;
  box-shadow: none;
  outline: 0;
  padding: 10px;
  color: #1f222b;
  text-decoration: none;
}

.os-items.os-as-grid .os-item .os-item-i {
  display: block;
  padding: 10px;
  animation: 0.4s cubic-bezier(0.45, 1, 0.4, 1.2) 0ms LatepointSlideUp;
  animation-fill-mode: both;
}

.os-items.os-as-grid .os-item .os-item-i .os-item-img-w.os-with-avatar {
  padding: 0 15px 15px;
  transition: transform 0.2s ease;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
}

.os-items.os-as-grid
  .os-item
  .os-item-i
  .os-item-img-w.os-with-avatar
  .os-avatar {
  background-size: cover;
  background-position: center center;
  padding-bottom: 100%;
}

.inline-avatar {
  display: inline-block;
  .os-avatar-small {
    width: 55px;
    height: 55px;
    display: inline-block;
    background-size: cover;
    background-position: center center;
    margin: 0 1rem 0rem 0rem;
    position: relative;
    top: -6px;
    border-radius: 50%;
  }
  .agent-container {
    display: inline-block;
    line-height: 20px;
    .agent {
      display: block;
    }
  }
}

#confirm {
  background-color: #4caf50 !important;
}

.os-items .os-item .os-item-i .os-item-name-w {
  padding: 5px 0;
  margin-right: auto;
  display: block;
  transition: transform 0.2s ease;
  z-index: 2;
  position: relative;
  border-top: 1px solid #eee;
}

.os-items .os-item .os-item-i .os-item-name-w .os-item-name {
  font-weight: 800;
  font-size: 14px;
  display: block;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  letter-spacing: 1px;
}

.os-items .os-item-details-popup-btn {
  font-size: 12px;
  padding: 0;
  font-weight: 500;
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  z-index: 1;
  border: 0;
  transition: transform 0.2s ease;
  display: inline-block;
}

.os-items .os-item-details-popup-btn span {
  color: #caa569 !important;
  border-color: #222;
  display: inline-block;
  border-bottom: 1px dotted rgba(29, 123, 255, 0.5);
  padding: 2px 0 1px;
}

.back-button {
  color: #111 !important;
  font-weight: 500;
  box-shadow: none;
  padding: 8px 14px;
  font-size: 12px;
  letter-spacing: 0.2px;
}

.calendar > iframe {
  height: 550px !important;
}

.home__language-selection {
  position: fixed;
  bottom: 10px; /* Adjust the distance from the bottom */
  right: 10px; /* Adjust the distance from the right */
  display: flex;
  align-items: center;
}

.account-selection {
  position: fixed;
  bottom: 10px; /* Adjust the distance from the bottom */
  left: 0px; /* Adjust the distance from the right */
  display: flex;
  align-items: center;

  > a {
    font-size: 1.3rem;
    color: #caa569 !important;
    border-bottom: 1px dotted;
  }
}

.language-separator {
  margin: 0 10px; /* Adjust the spacing between buttons */
  height: 20px; /* Adjust the height of the separator */
  width: 1px;
  background-color: #caa569; /* Separator color */
}

.language-btn {
  background: none;
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  color: #caa569;
}

.times-header-label {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  color: #16171d;
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  > span {
    border-bottom: 1px dotted #222;
  }
}

.modal-header {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: space-between !important;
  padding: 1rem 1rem !important;
  border-bottom: 1px solid #dee2e6 !important;
  border-top-left-radius: calc(0.3rem - 1px) !important;
  border-top-right-radius: calc(0.3rem - 1px) !important;
}

::v-deep .modal-header .modal-title {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, sans-serif !important;
  font-weight: 900 !important;
  font-size: 21px !important;
  color: #caa569 !important;
}

.modal-header .close {
  padding: 1rem 1rem !important;
  margin: -1rem -1rem -1rem auto !important;
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .modal-right-side {
    display: none !important;
  }
}

.pricing-text {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, sans-serif !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  display: flex;
}

.pricing-text-t {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, sans-serif !important;
  font-weight: 700 !important;
  font-size: 13px !important;
}

.loader {
  position: absolute;
  top: 5%;
  left: 45%;
  display: inline-block;
  width: 40px;
  height: 40px;
}

.spinner {
  animation: rotate 1s linear infinite;
}

.path {
  stroke: #caa569;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.error-message {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.booked-already {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #04bb03;
}

@media screen and (max-width: 767px) {
  .d-flex {
    flex-direction: column; /* Change to a column layout on smaller screens */
  }

  .modal-left-side {
    order: 2; /* Move the left side below the right side on smaller screens */
  }

  /* Adjust styles for better mobile responsiveness */
  .services-list {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }

  .services-list label {
    flex: 1;
  }
}

@media (max-width: 360px) {
  .landing-page {
    height: 100vh; /* Adjust height for smaller screens */
  }

  .background-video video {
    height: auto; /* Ensure video height adjusts */
  }

  .custom-logo-size {
    height: 100px; /* Adjust logo size for smaller screens */
  }

  .modal-right-side {
    width: 100%; /* Make modal content full width on smaller screens */
  }

  .summary-body {
    padding: 20px; /* Adjust padding for summary body on smaller screens */
  }

  .services-list {
    flex-direction: row; /* Stack services vertically on smaller screens */
    margin-bottom: 0.5rem;
    padding: 0.5rem 0 0 0 !important;
  }

  .services-list label {
    width: auto; /* Reset label width */
    margin-bottom: 10px; /* Add margin between service items */
    font-size: 12px;
  }

  .services-list input[type='checkbox'] {
    margin-right: 10px; /* Add margin between checkbox and label */
  }

  .services-list .summary-price-item-w {
    font-size: 12px; /* Adjust price font size */
  }

  .calendar > iframe {
    height: 400px !important; /* Adjust calendar height for smaller screens */
  }

  .home__language-selection,
  .account-selection {
    flex-direction: row;
    align-items: baseline;
    bottom: 20px;
    right: 8px;
    transform: translateX(0);
    > button {
      margin: 0 5px;
    }
  }

  .book-app-button[data-v-10c75340] {
    padding: 15px 15px;
    font-size: 14px;
  }

  .language-separator {
    display: none; /* Hide language separator on smaller screens */
  }
}

.special-footer {
  padding: 20px 15px !important;
}

@media (max-width: 360px) {
  .special-footer {
    padding: 20px 3px !important;
  }
}

.cancellation-container {
  background: #ff5733; /* New hex color code */
  border: 1px solid #ff5733; /* Matching border color */
  box-shadow: 0 3px 20px 0 rgba(255, 87, 51, 0.6); /* Matching box-shadow color */
  border-radius: 100px;
  font-size: 10px;

  svg {
    color: #fff2f4;
  }

  .cancelation {
    font-size: 8px;
    font-weight: 900;
    letter-spacing: 0.5px;
    color: #fff2f4;
  }
}

.screen {
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  overflow: hidden;
  width: 100%;
  height: 430px;
  background-color: #fff;
  border-radius: 15px;
  text-align: center;
}

.screen #topIcon {
  position: absolute;
  left: 50%;
  top: 30%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.screen .border-top {
  position: absolute;
  top: 0;
  height: 10px;
  width: 100%;
  background-color: #4a934a;
}

.screen h3 {
  font-weight: 700;
  font-size: 24px;
  color: #606060;
  letter-spacing: 0;
  position: absolute;
  left: 50%;
  top: 55%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.screen p {
  font-weight: 400;
  font-size: 16px;
  color: #616161;
  letter-spacing: 0.18px;
  position: absolute;
  left: 50%;
  top: 68%;
  width: 90%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.screen button {
  background: #4a934a;
  border: 1px solid #4a934a;
  box-shadow: 0 3px 20px 0 #4a934a;
  border-radius: 100px;
  letter-spacing: 1.5px;
  color: #fff;
  width: 186px;
  height: 40px;
  position: absolute;
  transform: translate(-50%, -50%);
  bottom: 0;
  font-weight: 900;
  cursor: pointer;
}

.screen button:focus {
  outline: 0;
}
#Bubbles {
  visibility: hidden;
}

.un {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.tr {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.btn-overlay {
  background-color: #43d0f1;
  border: 0;
  color: #fff;
  opacity: 0.6;
  padding: 10px 15px;
  border-radius: 100px;
  font-size: 12px;
  letter-spacing: 0.8px;
  z-index: 999;
  width: 100px;
}

.btn-overlay:hover {
  opacity: 1;
}

#restart {
  position: fixed;
  right: 10px;
  top: 10px;
}

#invert {
  position: fixed;
  right: 10px;
  top: 55px;
}
</style>
